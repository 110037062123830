<template>
  <v-container class="max-container">
    <v-card
      class="mx-auto card-box"
      tile
      dark
      color="black"
    >
      <div v-if="redirect" class="back-icon" @click="goBack">
        <v-btn icon color="purple accent-2" x-large>
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <div class="layout column align-center">
          <div class="v-card__title justify-center display-1 mb-2">
            <v-avatar size="80">
              <v-img :src="user.photo_url" max-height="80" max-width="80" />
            </v-avatar>
          </div>
          <div class="v-card__subtitle justify-center" style="text-align:center;margin-top:1em">
            <div class="display-3 mb-1 wallet-title">
              ${{ balance }}
            </div>
            <div class="email-title">
              Account Balance
            </div>
          </div>
        </div>
      </v-card-text>
      <div class="top-box">
        <div class="d-flex">
          <div class="pa-2" style="line-height:45px">Select Payment Method</div>
          <div class="pa-2 ml-auto">
            <v-btn
              plain
              text
              tile
              color="primary"
              @click="sheet=true"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              Add card
            </v-btn>
          </div>
        </div>
        <v-select
          v-model="card"
          color="grey darken-3"
          height="50"
          solo
          :items="cards"
          return-object
        />
        <div>
          <span>Select the amount</span>
          <div>
            <v-row
              align="center"
              align-content="center"
              justify="center"
            >
              <v-col
                v-for="item in topupItems"
                :key="item.key"
                cols="4"
                xs="6"
                align-self="center"
                @click="changeSelected(item)"
              >
                <div class="amount-box" :class="{'selected': item.selected}">
                  <div>${{ item.discount_price / 100 }}</div>
                  <div v-if="item.origin_price!==item.discount_price" class="amount-discount">for ${{ item.origin_price/100 }}</div>
                </div>
              </v-col>
            </v-row>
          </div>
          <div v-show="topupHistory.length===0" class="activity-box">You will get extra $5 when you top up for the first time.</div>
          <div class="button-box top-up">
            <v-btn block depressed class="tile text-none" color="primary" :disabled="loading" :loading="loading" @click="topUp()">Top Up</v-btn>
          </div>
        </div>
      </div>
      <c-snack-bar ref="csnackbar" />
      <div class="addpayment-box">
        <v-bottom-sheet
          v-model="sheet"
          inset
          hide-overlay
          max-width="500px"
          max-height="100%"
        >
          <v-sheet
            dark
            class="text-center"
            style="background:black"
            height="100vh"
          >
            <div style="padding:20px">
              <add-payment-form ref="paymentForm" />
              <div class="button-box">
                <v-btn block depressed class="tile text-none" color="primary" :disabled="loading" :loading="loading" @click="addPayment">
                  Top Up <span v-if="topupItems.length>0">${{ topUpItem.discount_price / 100 }}</span>
                </v-btn>
                <v-btn outlined block depressed class="tile text-none" dark :disabled="loading" @click="goTo">Cancel</v-btn>
              </div>
            </div>

          </v-sheet>
        </v-bottom-sheet>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { getPaymentMethods, getUserBalance, topUp, addPayment as addCard, getTextByCode, getTopupHistory } from '@/api/user'
import CSnackBar from './components/snackbar'
import OverDialog from './components/overDialog'
import PaymentMixin from '@/mixins/paymentMixin'

export default {
  name: 'Wallet',
  components: {
    // eslint-disable-next-line
    CSnackBar,
    // eslint-disable-next-line
    OverDialog
  },
  mixins: [PaymentMixin],
  data() {
    return {
      redirect: null,
      sheet: false,
      loading: false,
      user: {},
      items: [
        { 'text': '283889', 'value': 2789379 },
        { 'text': '328889', 'value': 239379 }
      ],
      topupItems: [],
      cards: [],
      card: null,
      balance: 0,
      topupHistory: [{}]
    }
  },
  computed: {
    topUpItem() {
      return this.topupItems.find(item => item.selected)
    }
  },
  created() {
    this.redirect = this.$route.query && this.$route.query.redirect
    this.user = this.$store.state.user.userInfo.user
    this.$nextTick(() => {
      this.getTopupHistory()
      this.getTopupItems()
      this.getBalance()
      this.getCards()
    })
  },
  methods: {
    getTopupHistory() {
      getTopupHistory().then(res => {
        this.topupHistory = res.data
      })
    },
    getTopupItems() {
      getTextByCode({ 'code': 'topup_value' }).then(res => {
        this.topupItems = res.data.data.map(item => {
          item['selected'] = false
          return item
        })
        this.topupItems.sort((a, b) => a.discount_price - b.discount_price)
        this.topupItems[0]['selected'] = true
      })
    },
    changeSelected(item) {
      this.topupItems.forEach(_item => {
        _item.selected = false
      })
      item.selected = true
    },
    async addPayment() {
      if (!this.$refs.paymentForm.validate()) {
        return
      }
      if (this.loading) {
        return
      }
      this.loading = true
      const data = this.$refs.paymentForm.getPaymentData()
      try {
        const amountItem = this.topupItems.find(item => item.selected)
        const res = await addCard({ pay_go_card: true, trial_check: true, try_charge_amount: amountItem.discount_price, card: data })
        if (res.code !== 0) {
          this.showNackBar('error', res.message, 6000)
          this.loading = false
          return
        } else {
          await this.topUp(res.data.pay_capture)
          this.goTo()
          this.loading = false
          this.getCards()
        }
      } catch (error) {
        console.log(error)
        this.showNackBar('error', 'Some errors have occurred, please try again later.', 6000)
        this.loading = false
      }
    },
    async topUp(pay_capture) {
      const amountItem = this.topupItems.find(item => item.selected)
      if (!amountItem) {
        this.showNackBar('info', 'Please select the amount to recharge first')
        return
      }
      let data = {}
      if (pay_capture) {
        data = { 'topup_key': amountItem.key, 'pay_capture': pay_capture }
      } else {
        if (this.cards.length === 0) {
          this.showNackBar('info', 'Please add payment method first')
          this.sheet = true
          return
        }
        data = { 'topup_key': amountItem.key, 'card_id': this.card.id }
      }

      this.loading = true
      topUp(data).then(async res => {
        let msgColor = 'success'
        if (res.code !== 0) {
          msgColor = 'error'
          this.showNackBar(msgColor, res.message)
        } else {
          this.showNackBar(msgColor, res.message)
          this.$store.dispatch('user/getInfo')
        }
        this.getBalance()
        setTimeout(() => {
          this.goBack()
        }, 2 * 1000)
      }, () => {
        this.showNackBar('error', 'Something went wrong, please try again later or contact customer service')
      }).finally(() => {
        this.loading = false
      })
    },
    getCards() {
      getPaymentMethods().then(res => {
        let cards = []
        cards = res.data.map(item => {
          item.text = `${item.brand} ********${item.card_number}`
          item.value = item.id
          return item
        })
        cards.sort((a, b) => {
          return a.default === b.default ? 0 : a.default > b.default ? -1 : 1
        })
        this.cards = cards
        if (cards.length > 0) {
          this.card = this.cards[0]
        }
      }).finally(() => {
      })
    },
    getBalance() {
      getUserBalance().then(res => {
        this.balance = res.data.balance / 100
      })
    },
    async goTo() {
      this.$refs.paymentForm.reset()
      this.sheet = !this.sheet
    },
    showNackBar(color, message, timeout = 5000) {
      this.$refs.csnackbar.show(message, color, timeout)
    },
    goBack() {
      if (this.redirect) {
        const query = this.$route.query && this.$route.query
        if (query && query.tab) {
          this.$router.push({ path: decodeURIComponent(this.redirect), query: { 'tab': query.tab }})
          return
        }
        this.$router.push({ path: decodeURIComponent(this.redirect) })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.wallet-title{
    color: #E040FB;
    vertical-align: middle;
}
.amount-box{
    min-width: 112px;
    text-align: center;
    background: #1E1E1E;
    min-height: 60px;
    vertical-align: middle;
    border-radius: 4px;
    font-size: 18px;
    padding: 10px;
    .amount-discount{
      font-size: 16px;
      color: #E040FB;
    }
}

.amount-box.selected{
    border: 3px solid #E040FB;
    width: 120px;
}

.button-box{

  &.top-up{
    margin-top: 10px;
  }
  .v-btn:nth-child(1){
    background-image: linear-gradient(45deg,#8C9EFF, #E040FB)!important;
    font-weight: bold;
    letter-spacing: 0.05em;
    font-size: 14px;
    min-width: 0;
    .v-btn__content{
      padding: 0;
    }
  }

  .v-btn:nth-child(2){
    margin-top: 10px;
    letter-spacing: 0.01em;
    font-size: 14px;
  }
  .origin-price{
    text-decoration-line: line-through;
    padding-right: 10px;
  }

  span{
    padding-left: 5px;
    font-size: medium!important;
  }
}

.addpayment-box{
    .payment-form{
        padding: 20px;
    }
}

.activity-box{
  color: #E040FB;
  font-size: 16px;
  margin-top: 10px;
}

</style>
