import { addPayment, getTextByCode, subscription } from '@/api/user'
import CSnackBar from '@/views/auth/components/snackbar'
import OverDialog from '@/views/auth/components/overDialog'
import AddPaymentForm from '@/views/auth/components/addPaymentForm'

export default {
  components: {
    CSnackBar,
    OverDialog,
    AddPaymentForm
  },
  computed: {
    payGoUser() {
      return this.$store.state.user.userInfo.pay_as_go_user
    },
    isTrial1() {
      const sub = this.$store.state.user.userInfo.subscription
      if (sub) {
        return sub.status === 'trial_1'
      } else {
        return false
      }
    },
    trial7day() {
      const sub = this.$store.state.user.userInfo.subscription
      return !sub || (sub && sub.status === 'trial_1' && sub.trial_end > Math.floor(Date.now() / 1000))
    },
    newUser() {
      const sub = this.$store.state.user.userInfo.subscription
      return !sub
    },
    sub_canceled() {
      const sub = this.$store.state.user.userInfo.subscription
      return sub && sub.status === 'cancel' // 用户订阅已被取消状态
    },
    try_charge_amount() {
      console.log('hi')
      const sub_data = this.$store.state.user.sub_data
      const current_sub = this.$store.state.user.userInfo.subscription
      let try_amount = 100
      if (sub_data.length > 0 && Object.keys(sub_data[0]).includes('discount_price')) {
        try_amount = sub_data
          .map((item) => {
            return Object.keys(item).includes('discount_price') ? item.discount_price : 0
          })
          .reduce((acc, val) => acc + val)
        if (try_amount > 0 && this.$route.query.st && Object.keys(this.$store.getters.sub_discounts).includes(this.$route.query.st.toString())) {
          try_amount = parseInt((try_amount * parseInt(this.$route.query.st) * this.$store.getters.sub_discounts[this.$route.query.st.toString()]).toFixed())
        }
      } else if (current_sub) {
        try_amount = current_sub.unit_amount
      }
      if (this.payGoUser) {
        return 100
      }
      return try_amount === 0 ? 100 : try_amount
    }
  },
  methods: {
    async addPayment() {
      if (!this.$refs.paymentForm.validate()) {
        return
      }
      if (this.loading) {
        return
      }
      this.loading = true
      const data = this.$refs.paymentForm.getPaymentData()
      try {
        const res = await addPayment({ trial_check: true, try_charge_amount: this.try_charge_amount, card: data })
        if (res.code !== 0) {
          this.showNackBar('error', res.message, 6000)
          this.loading = false
          return
        } else {
          if (this.$store.state.user.sub_data.length > 0 && (this.newUser || this.isTrial1 || this.sub_canceled)) {
            await this.createSub()
            return
          }
          await this.goTo()
        }
      } catch (error) {
        this.showNackBar('error', 'Some errors have occurred, please try again later.', 6000)
      }
    },
    async createSub(is_active) {
      const operations = await this.getOperations(is_active)
      const platform_list = this.$store.state.user.sub_data.map(item => item.platform_name)
      subscription({ 'operations': operations, 'platform_list': platform_list, 'interval_count': this.$route.query.st }).then(async(res) => {
        if (res.code === 0) {
          if (is_active) {
            this.$refs.overdialog.controlLoading(false)
            this.$refs.overdialog.controlShowDialog(false)
          }
          this.showNackBar('info', res.message)
          await this.goTo()
          return
        }
        // 提示有绑定支付方式， 但无试用机会, 是否进入正式订阅 (active) 状态
        // eslint-disable-next-line
          if (res.code !== 0 && res.hasOwnProperty('data') && res.data && res.data.hasOwnProperty('card')&& res.data.hasOwnProperty('trial') && res.data['card'] && !res.data['trial']) {
          this.showNackBar('info', res.message)
          this.$refs.overdialog.controlDialog(true, res.data.active_tip, 'Confirm')
          return
        }

        if (res.code !== 0) {
          this.showNackBar('error', res.message)
          return
        }

        this.showNackBar('error', res.message)
      }, () => {
        this.showNackBar('error', 'Some errors have occurred, please try again later.')
      }).finally(() => {
        this.loading = false
        if (is_active) {
          this.$refs.overdialog.controlLoading(false)
          this.$refs.overdialog.controlShowDialog(false)
        }
      })
    },
    async getOperations(is_active) {
      // is_active: 是否进入正式订阅（active）状态
      if (is_active) {
        return 'active'
      }

      if (this.newUser && this.payGoUser) {
        return 'active'
      }

      if (this.newUser) {
        return 'trial_2'
      }

      if (this.isTrial1) {
        return 'trial_3'
      }

      if (this.sub_canceled) {
        return 'recover'
      }
    },
    async goTo() {
      if (window.isAndroidWebView()) {
        window.AndroidCallback.onSubscriptionCallback('success')
        return
      }

      if (window.isWebKit()) {
        window.webkit.messageHandlers.refreshUserSubInfo.postMessage('success')
        return
      }

      this.$router.push({ name: 'mychannel' })
    },
    showNackBar(color, message, timeout = 5000) {
      this.$refs.csnackbar.show(message, color, timeout)
    },
    getTextByCode() {
      getTextByCode({ 'code': 'new_or_trial1_card_tips' }).then(res => {
        // eslint-disable-next-line
          if (res.data && res.data.hasOwnProperty('value')) {
          this.tip_text = res.data.value
        }
      })
    }
  }
}
